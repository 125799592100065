import {
  Aed,
  BarChart,
  Bug,
  CodeSandbox,
  Search,
  // Server,
  StatusUnknown,
  Storage,
  Test,
  Tools,
  User,
  VirtualMachine,
} from 'grommet-icons';
import React from 'react';
import { matchPath } from 'react-router-dom';
import { mapObject } from 'underscore';
import { buildUrl } from '@infosight/elmer/dist/utils/url';
import cacheWrapper from '../util/cacheWrapper';
import { iconSize } from '../primaryNav/constants';

/**
 * When creating a new microapp:
 * 1. Add the expected URLs to the `microappRoutes` object
 * 2. Add the microapp title and icon to the `microappData` object
 * 3. Add a home page for your microapp to the `microappData` object
 * 4. If this is a new independant microapp, add precedence to `microappData` to determine its order in the navigation
 * 5. If this is related to another app, add the parent to `microappData`
 *
 * The key for these objects is your microapp id
 */

/**
 * Known/Approved routes that each microapp is expected to handle.
 *
 * This adds the ability to wait for a microapp to load so it can handle a deep link.
 * It also prevents core and domain teams from accidentally squatting on generically named routes.
 *
 * TODO It's very low risk, but there is a coupling between Shell and microapp deployments. Consider externalizing this config in microapp-manifest or other
 */
const microappRoutes = {
  // This makes demos and quickstarts smooth
  sample: [
    '/infrastructure/storage/sample',
    '/dashboards/sample',
    '/resources/sample',
  ],
  'hello-world': ['/dashboards/hello-world'],
  prostacksizingtool: ['/resources/dhcisizingtool'],
  'nimble-acumen': ['/nimble-acumen'],
  wellness: ['/unifiedwellness'],
  vmware: ['/infrastructure/virtualization/vmware'],
  nimble: [
    '/dashboards/nimble',
    '/infrastructure/storage/nimble',
    '/resources/nimble',
    '/settings/nimble',

    // TODO Refactor bulletin management so it can live in shell or iris.
    // This is a hack to get rid of all backbone dependencies. The only thing currently in here is bulleting management, so it's not so bad yet.
    '/settings/admin',
  ],
  resources: ['/resources/support'],
  storeserv: ['/dashboards/storeserv', '/infrastructure/storage/storeserv'],
  storeonce: ['/infrastructure/storage/storeonce'],
  rmc: ['/infrastructure/storage/rmc'],
  oculus: ['/infrastructure/general'],
  unifiedAssets: ['/unifiedAssets'],
  user: ['/user'],
  welcome: [
    '/home',
    '/landing',
    '/activeBulletins',
    '/registration/nimble',
    '/developers',
    '/whatsNew',
  ],
  proliant: ['/infrastructure/compute/proliant', '/dashboards/proliant'],
  iam: ['/settings/iam'],
  storeservlabs: ['/dashboards/storeservlabs'],
  prostack: ['/infrastructure/solution/dhci'],
  'simplivity-wellness': ['/dashboards/simplivity'],
  'simplivity-analytics': [
    '/infrastructure/hyperconverged/simplivity-analytics',
    '/dashboards/simplivity-analytics',
    '/settings/simplivity-analytics',
  ],
  'template-editor': ['/iris/template-editor'],
  bigdata: [
    '/dashboards/bigdata',
    '/infrastructure/bigdata',
    '/resources/bigdata',
  ],
  infosightlabs: [
    '/dashboards/infosightlabs',
    '/infrastructure/infosightlabs',
    '/resources/infosightlabs',
  ],
  broken: [
    '/infrastructure/storage/broken',
    '/dashboards/broken',
    '/resources/broken',
  ],
  iris: ['/iris'],
};

// Higher value indicates higher precedence in nav menu order
export const navPrecedence = {
  GERNERAL: 120,
  WELLNESS: 115,
  UNIFIED_ASSETS: 112,
  SERVERS: 110,
  NIMBLE_STORAGE: 100,
  PROSTACK: 90,
  STORESERV: 80,
  RAZOR_CREST: 74,
  OCULUS: 70,
  VMWARE: 60,
  SIMPLIVITY: 40,
  STOREONCE: 30,
  IRIS: 11,
  SAMPLE: 10,
  BROKEN: 3,
  TEMPLATE_EDITOR: 1,
  LOWEST_PRECENDENCE_APP: 0,
};

export const microappData = {
  wellness: {
    title: 'Unified Wellness Dashboard',
    icon: <Aed size={iconSize} />,
    precedence: navPrecedence.WELLNESS,
    alwaysShowExperimental: true,
    noInventoryHome: buildUrl('unifiedwellness'),
  },
  'template-editor': {
    title: 'Template Editor',
    icon: <Aed size={iconSize} />,
    precedence: navPrecedence.TEMPLATE_EDITOR,
  },
  vmware: {
    title: 'VMware',
    icon: <VirtualMachine size={iconSize} />,
    precedence: navPrecedence.VMWARE,
    home: buildUrl('infrastructure', 'virtualization', 'vmware', 'vms', 'list'),
    // distinct from children and parents which are essentially the same microapp under multiple codebases
    // replicatedIn means this microapp is standalone AND replicated in another microapps nav
    replicatedIn: ['nimble'],
    getInventory: true,
  },
  nimble: {
    title: 'HPE Alletra 6000, Alletra 5000, Nimble Storage',
    icon: <Storage size={iconSize} />,
    precedence: navPrecedence.NIMBLE_STORAGE,
    home: buildUrl('dashboards', 'nimble', 'operational'),
    getInventory: true,
  },
  'nimble-acumen': { parent: 'nimble' },
  storeserv: {
    title: 'HPE Alletra 9000, Primera, 3PAR',
    icon: <Storage size={iconSize} />,
    precedence: navPrecedence.STORESERV,
    home: buildUrl('infrastructure', 'storage', 'storeserv', 'new', 'systems'),
    getInventory: true,
  },
  storeonce: {
    title: 'StoreOnce',
    icon: <Storage size={iconSize} />,
    precedence: navPrecedence.STOREONCE,
    home: buildUrl('infrastructure', 'storage', 'storeonce', 'systems'),
    getInventory: true,
  },
  oculus: {
    title: 'HPE Alletra 9000, Primera, 3PAR, StoreOnce',
    icon: <Storage size={iconSize} />,
    precedence: navPrecedence.OCULUS,
  },
  // sfr is used in a couple places in the oculus microapp
  sfr: {
    parent: 'oculus',
  },
  // this shows up when sfr is loaded so we have to keep it around
  rmc: {
    parent: 'sfr',
  },
  // proliant: {
  //   title: 'Servers',
  //   icon: <Server size={iconSize} />,
  //   alwaysShow: true,
  //   precedence: navPrecedence.SERVERS,
  //   home: buildUrl('dashboards', 'proliant', 'operational'),
  //   noInventoryHome: buildUrl(
  //     'infrastructure',
  //     'compute',
  //     'proliant',
  //     'analyzelogs'
  //   ),
  //   getInventory: true,
  // },
  storeservlabs: {
    title: '3PAR StoreServ Labs',
    icon: <Test size={iconSize} />,
    parent: 'storeserv',
  },
  prostack: {
    title: 'Nimble Storage dHCI',
    icon: <Storage size={iconSize} />,
    precedence: navPrecedence.PROSTACK,
    home: buildUrl('infrastructure', 'solution', 'dhci', 'solutions'),
    getInventory: true,
  },
  'simplivity-wellness': {
    title: 'SimpliVity Wellness',
    icon: <Aed size={iconSize} />,
    parent: 'simplivity',
  },
  'simplivity-analytics': {
    title: 'SimpliVity Analytics',
    icon: <BarChart size={iconSize} />,
    parent: 'simplivity',
  },
  iam: {
    title: 'Identity and Access Management',
    icon: <User size={iconSize} />,
    isCore: true,
  },
  sample: {
    title: 'Sample',
    icon: <CodeSandbox size={iconSize} />,
    alwaysShow: true,
    precedence: navPrecedence.SAMPLE,
    home: buildUrl('dashboards', 'sample', 'page-1'),
  },
  broken: {
    title: 'Broken',
    icon: <Bug size={iconSize} />,
    alwaysShow: true,
    precedence: navPrecedence.BROKEN,
    experimental: true,
  },
  infosightlabs: {
    title: 'Labs',
    icon: <Test size={iconSize} />,
    parent: 'nimble',
  },
  simplivity: {
    title: 'SimpliVity',
    icon: <Storage size={iconSize} />,
    precedence: navPrecedence.SIMPLIVITY,
    home: buildUrl('dashboards', 'simplivity-analytics', 'storageefficiency'),
    getInventory: true,
    manifestKey: 'simplivity-analytics',
  },
  prostacksizingtool: {
    parent: 'nimble',
  },
  iris: {
    title: 'IRIS',
    icon: <Tools size={iconSize} />,
    precedence: navPrecedence.IRIS,
    dontShow: true,
    getInventory: true,
  },
  shell: {
    isCore: true,
  },
  resources: {
    isCore: true,
  },
  settings: {
    isCore: true,
  },
  search: {
    isCore: true,
  },
  unifiedAssets: {
    title: 'Unified Asset Dashboard',
    icon: <Search size={iconSize} />,
    isCore: true,
    alwaysShow: true,
    precedence: navPrecedence.UNIFIED_ASSETS,
    experimental: true,
  },
  user: {
    isCore: true,
  },
  welcome: {
    isCore: true,
  },
  general: {
    precedence: navPrecedence.GERNERAL,
  },
};

/**
 * Microapp titles for when the microapps must be referred to by human readable names
 *
 * This is only tangentially related but is included in this file so that people only have one place to update when adding a new microapp.
 */
export const microappTitles = mapObject(microappData, (data) => data.title);

export const getMicroappIcon = (microapp) =>
  (microappData[microapp] && microappData[microapp].icon) || <StatusUnknown />;

export const getNavPrecedence = (microapp) =>
  (microappData[microapp] && microappData[microapp].precedence) ||
  navPrecedence.LOWEST_PRECENDENCE_APP;

// this will need to use inventory to tell whether to use default or secondary home pages
export const getMicroappHome = (
  microapp,
  presence,
  presenceError,
  experimentalMode
) => {
  let defaultPage;
  if (
    microappData[microapp] &&
    (!presenceError ||
      microappData[microapp].alwaysShow ||
      (experimentalMode && microappData[microapp].alwaysShowExperimental))
  ) {
    // if (microappData[microapp]) {
    defaultPage = presence
      ? microappData[microapp].home
      : microappData[microapp].noInventoryHome;
  }
  return defaultPage || '/home';
};

export const isKnownMicroappId = (appId) =>
  !!(appId && Object.keys(microappData).includes(appId));

const getMicroapp = (pathName) => {
  const checkPathAgainstRoute = (route) =>
    !!(
      matchPath(pathName, { path: route }) ||
      matchPath(pathName, { path: `/org/:tenantId${route}` })
    );

  return Object.keys(microappRoutes).find((key) =>
    microappRoutes[key].find((route) => checkPathAgainstRoute(route))
  );
};

export const getParentMicroapp = (microapp) =>
  (microappData[microapp] && microappData[microapp].parent) || microapp;

export const getMicroappByPath = cacheWrapper(getMicroapp);

export default microappRoutes;
